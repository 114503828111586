import api from '../../../api'
import { checkResponse } from '../../error'
export const vouchers = {
	namespaced: true,
	actions: {
		async getVoucherInfo(_, payload) {
			return api.vouchers.getVoucherInfo(payload).then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
		async validateVoucher(_, payload) {
			return api.vouchers.validateVoucher(payload).then((response) => {
				var result = checkResponse(response)
				if (payload.callback) payload.callback(result)
			})
		},
	},
}
