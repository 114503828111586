import axios from 'axios'

export function authReq(url, method, payload, authConfig) {
	return axios({
		method: method,
		url: url,
		data: payload,
		headers: authConfig.headers,
	})
		.then(function (response) {
			return response
		})
		.catch(function (error) {
			return error.response
		})
}
export function noAuthReq(url, method, payload) {
	return axios({
		method: method,
		url: url,
		data: payload,
	})
		.then(function (response) {
			return response
		})
		.catch(function (error) {
			return error.response
		})
}

export function authReqGet(url, method, params, authConfig) {
	return axios({
		method: method,
		url: url,
		params: params,
		headers: authConfig.headers,
	})
		.then(function (response) {
			return response
		})
		.catch(function (error) {
			return error.response
		})
}

export function noAuthReqGet(url, method, params) {
	return axios({
		method: method,
		url: url,
		params: params,
	})
		.then(function (response) {
			return response
		})
		.catch(function (error) {
			return error.response
		})
}
